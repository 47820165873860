// admin menu visible control

(function () {

    window.addEventListener('load', function () {
        document.querySelectorAll('[data-app-navigation-button]')
            .forEach(function (button) {
                button.addEventListener('click', function (e) {
                    document.getElementById(
                        e.currentTarget.getAttribute('data-app-navigation-button')
                    ).classList.toggle('appNavigation__panel--open');
                });
            })
    });
})();
// slideshow
(function () {
    var init = function (root) {
        var viewport = root.querySelector('[data-slide-show-viewport]');
        var slides = root.querySelector('[data-slide-show-slides]');
        var teazers = root.querySelector('[data-slide-show-teazers]');
        var length = teazers.querySelectorAll('[data-slide-show-teazer]').length;
        var currentSlide = 0;
        var interval = 0;
        teazers.children[0].classList.add('current');

        var setSizes = function () {
            var rootBox = root.getBoundingClientRect();
            var viewportBox = viewport.getBoundingClientRect();
            var slidesBox = slides.getBoundingClientRect();


            var teazersOffset = {width: 0, height: 0};

            if (window.innerWidth >= 900) {
                var teazerSize = {height: 150, width: 230};
                teazersOffset.height = ((rootBox.height - teazerSize.height * length) / (length - 1)) * currentSlide;
                teazers.style.transform = 'translate(0, ' + (teazersOffset.height) + 'px)';
            } else {
                teazerSize = {height: 180, width: 230};
                teazersOffset.width = ((rootBox.width - teazerSize.width * length) / (length - 1)) * currentSlide;
                teazers.style.transform = 'translate(' + (teazersOffset.width) + 'px, 0)';
            }

            teazers.querySelectorAll('[data-slide-show-teazer]').forEach(function (element) {
                element.classList.remove('current');
            });
            teazers.children[currentSlide].classList.add('current');

            slides.style.transform = 'translate( 0, ' + ((viewportBox.height - slidesBox.height) / (length - 1) * currentSlide) + 'px)';

        };

        interval = setInterval(function () {
            currentSlide = (currentSlide + 1) % length;
            setSizes();
        }, 3000);

        var timeout = 0;
        window.addEventListener("resize", function () {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                var transition = teazers.style.transition;
                teazers.style.transition = 'transform 0s linear 0s';
                setSizes();
                setTimeout(function () {
                    teazers.style.transition = transition;
                }, 100);
            }, 100);
        });

    };
    window.addEventListener("load", function () {
        document.querySelectorAll('[data-slide-show]').forEach(init);
    });
})();

// app admin
(function () {
    const confirmSubmit = (formId) => {
        if (window.confirm("Do you really want to delete?")) {
            document.getElementById(formId).submit();
        }
    };
    window.addEventListener("load", function () {
        document.querySelectorAll('[data-delete-confirm-submit]').forEach( el =>{
            el.addEventListener("click", ()=>{
                if (window.confirm("Do you really want to delete?")) {
                    document.getElementById(el.getAttribute("data-delete-confirm-submit")).submit();
                }
            });
        });
        console.log()
    });
})();
// video
(function () {
    var init = function (video) {
        var button = video.querySelector('[data-video-play-button]');
        var videoId = video.getAttribute("data-video-id");
        button.removeAttribute("href");
        var onClick = function (e) {
            var iframe = document.createElement('iframe');
            iframe.src = "https://www.youtube.com/embed/" + videoId + '?autoplay=1&mute=1';
            iframe.width = "100%";
            iframe.height = "100%";
            iframe.allowfullscreen = "1";
            video.innerHTML = "";
            video.appendChild(iframe);
            button.removeEventListener("click", onClick);
        };
        video.style.backgroundImage = "url(https://img.youtube.com/vi/" + videoId + "/0.jpg)";
        video.style.width = "100%";
        video.style.height = "100%";
        button.addEventListener("click", onClick);

    };

    window.addEventListener("load", function () {
        document.querySelectorAll('[data-video-id]').forEach(init)
    });
})();
// browse product
(function () {
    var init = function () {
        var element = document.querySelector('[data-product-previews]');
        if (!!element) {


            var previews = element.querySelectorAll('[data-product-preview]');

            var setImage = function (preview) {
                previews.forEach(function (preview) {
                    preview.classList.remove('product__preview--current');
                });
                preview.classList.add('product__preview--current');

                var src = preview.getAttribute("data-product-preview");
                var img = document.querySelector('[data-product-image] img')
                img.src = src;
                img.srcSet = src + ' 100w';
            };

            previews.forEach(function (element) {
                element.addEventListener('click', function (e) {
                    setImage(e.currentTarget);
                })
            });
            previews[0].classList.add('product__preview--current');
        }
    };
    window.addEventListener('load', init);
})();

