(function () {
    const onDeleteButtonClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const button = e.currentTarget;
        const url = button.parentNode.getAttribute('data-entry-deletable');
        if (window.confirm("Do you really want to delete?")) {
            fetch(url, {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')['content']
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
                .then(() => {
                    const node = button.parentNode;
                    node.parentNode.removeChild(node);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };


    const createDeleteButton = () => {
        const button = document.createElement('button');
        //button.classList.add('iconButton');
        //button.classList.add('d-block');
        //button.classList.add('btn-sm');

        button.style.ouline = 'none';
        button.style.display = 'block';
        button.style.width = '32px';
        button.style.height = '32px';
        button.style.padding = '6px';
        button.style.borderRadius = 3 + 'px';
        button.style.border = 'none';
        button.style.right = 5 + 'px';
        button.style.bottom = 5 + 'px';
        button.style.position = 'absolute';
        button.style.zIndex = 1 + '';
        button.style.opacity = 0.5 + '';
        button.type = 'button';
        button.onmouseover = function () {
            button.style.opacity = 1 + '';
        };
        button.onmouseout = function () {
            button.style.opacity = 0.5 + '';
        };
        button.innerHTML = '<svg style="display: block" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">' +
            '<path d="M17 2h-3.5l-1-1h-5l-1 1H3v2h14zM4 17a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5H4z"/>' +
            '</svg>';
        return button;
    };


    const init = () => {
        document.querySelectorAll('[data-entry-deletable]').forEach((entry) => {
            const button = createDeleteButton();
            entry.appendChild(button);
            button.addEventListener('click', onDeleteButtonClick);
        });
    };
    window.addEventListener('load', init);

}());
