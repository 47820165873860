(function () {
    const onSort = function (entry, dstIndex) {
        const url = entry.getAttribute("data-entry-sortable");
        fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')['content']
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({position: dstIndex })
        })
            .catch(function (err) {
                console.error(err);
            });
    };

    const appendNumber = function (parent) {
        const lineHeight = 20;
        const numberElement = document.createElement('div');
        numberElement.innerHTML = 2;
        numberElement.setAttribute('data-entry-sortable-number', '');
        numberElement.style.width = lineHeight + 'px';
        numberElement.style.height = lineHeight + 'px';
        numberElement.style.lineHeight = lineHeight + 'px';
        numberElement.style.position = 'absolute';
        numberElement.style.left = 2 + 'px';
        numberElement.style.top = 2 + 'px';
        numberElement.style.background = 'rgba(255,255,255,0.5)';
        numberElement.style.color = 'black';
        numberElement.style.textAlign = 'center';
        numberElement.style.borderRadius = '50%';
        numberElement.style.fontSize = '0.75rem';
        parent.appendChild(numberElement);
    };

    const setNumbers = function (list) {
        const entries = list.querySelectorAll('[data-entry-sortable]');
        for (let i = 0; i < entries.length; i++) {
            entries[i].querySelector('[data-entry-sortable-number]').innerHTML = i + 1;
        }
    };

    const createDraggableNode = function (element) {
        const entryClientRect = element.getBoundingClientRect();
        const result = element.cloneNode();
        result.innerHTML = element.innerHTML;
        const style = result.style;
        style.width = entryClientRect.width + 'px';
        style.height = entryClientRect.height + 'px';
        style.display = 'block';
        style.position = 'fixed';
        style.top = entryClientRect.top + 'px';
        style.left = entryClientRect.left + 'px';
        style.cursor = 'pointer';
        style.zIndex = 2;
        style.boxShadow = '0px 0px 4px rgba(0,0,0,1)';
        return result;
    };
    const createArrow = () => {
        const result = document.createElement('div');
        result.innerHTML = '<svg width="24px" height="24px" viewBox="0 0 24 24" style="display:block"><polygon fill="#00aeff" points="18.066,12 18.066,0 5.934,0 5.934,12 0,12 12,24 24,12 "/></svg>';
        result.width = 48 + 'px';
        result.height = 48 + 'px';
        result.style.position = 'fixed';
        result.style.zIndex = 1;
        result.style.transform = 'translate(-50%, -100%)';
        result.style.transition = 'top 0.4s, left 0.4s';
        return result;
    };
    const createMap = (list) => {
        return [...list.querySelectorAll('[data-entry-sortable]')].reduce((result, current) => {
            result.push(current.getBoundingClientRect());
            return result;
        }, []);
    };

    const getDestinationIndex = (map, x, y) => {
        return map.reduce((result, current, index) => {
            if (y > current.top && y < current.top + current.height
                && x > current.left && x < current.left + current.width) {
                return index;
            }
            return result;
        }, -1);
    };


    const startSort = function (e) {
        e.preventDefault();
        const entry = e.currentTarget;
        const list = entry.closest('[data-entry-sortable-list]');
        const entries = [...list.querySelectorAll('[data-entry-sortable]')];
        const map = createMap(list);
        let srcIndex = entries.indexOf(entry);
        const entryClientRect = entry.getBoundingClientRect();
        // append movable entry
        let dragged = false;
        const draggableNode = createDraggableNode(entry);
        const arrowElement = createArrow();

        const offset = {x: e.clientX - entryClientRect.left, y: e.clientY - entryClientRect.top};
        const onMouseMove = function (e) {
            if (!dragged) {
                document.body.appendChild(draggableNode);
                document.body.appendChild(arrowElement);
            }
            dragged = true;
            draggableNode.style.top = (e.clientY - offset.y) + 'px';
            draggableNode.style.left = (e.clientX - offset.x) + 'px';

            const dstIndex = getDestinationIndex(map, e.clientX - offset.x, e.clientY - offset.y);
            entries.forEach((element)=>{
                element.classList.remove("entrySortableList__entryTarget");
            });
            if (dstIndex > -1 && dstIndex !== srcIndex) {
                arrowElement.style.top = map[dstIndex].top + 'px';
                arrowElement.style.left = dstIndex > srcIndex
                    ? map[dstIndex].width + map[dstIndex].left + 'px'
                    : map[dstIndex].left + 'px';
                arrowElement.style.opacity = 1;
                entries[dstIndex].classList.add("entrySortableList__entryTarget");
            } else {
                arrowElement.style.opacity = 0;
            }
        };

        const onMouseUp = function (e) {
            entry.style.opacity = 1;
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            if (!!dragged) {
                draggableNode.parentNode.removeChild(arrowElement);
                draggableNode.parentNode.removeChild(draggableNode);

                entries.forEach((element)=>{
                    element.classList.remove("entrySortableList__entryTarget");
                });

                const dstIndex = getDestinationIndex(map, e.clientX - offset.x, e.clientY - offset.y);
                if (dstIndex > -1 && srcIndex !== dstIndex) {
                    const pivotNode = entries[dstIndex];
                    if (dstIndex > srcIndex) {
                        pivotNode.parentNode.insertBefore(entry, pivotNode.nextSibling);
                    }
                    if (dstIndex < srcIndex) {
                        pivotNode.parentNode.insertBefore(entry, pivotNode);
                    }
                    setNumbers(list);
                    onSort(entry, dstIndex);
                }
                e.preventDefault();
                return false;
            }
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        entry.style.opacity = '0.5';
    };


    const init = function (list) {
        const entries = list.querySelectorAll('[data-entry-sortable]');
        const urls = {};
        for (let i = 0; i < entries.length; i++) {
            //console.log(entries[i].getAttribute('[data-entry-sortable]'));
            urls[entries[i].getAttribute('data-entry-sortable')] = 1;
            entries[i].addEventListener('mousedown', startSort);
            appendNumber(entries[i]);
        }
        setNumbers(list);
    };

    window.addEventListener('load', function () {
        document.querySelectorAll('[data-entry-sortable-list]').forEach(init);
    });

})();
