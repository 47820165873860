(function () {
    const init = (root) => {
        const input = root.querySelector('[data-upload-multiple-files-input]');
        const reports = root.querySelector('[data-upload-multiple-files-reports]');
        const loader = root.querySelector('[data-upload-multiple-files-loader]');
        const url = input.getAttribute('data-upload-multiple-files-input');

        const reset = () => {
            input.value = '';
            loader.style.display = 'none';
        };

        const onSelectFiles = () => {
            loader.style.display = 'flex';
            reports.innerHTML = '';

            const formData = new FormData();
            let currentIndex = 0;

            const addReport = (type, fileName, index, message = '') => {
                const templateId = type === 'success'
                    ? "filesUploader_report_success_template"
                    : "filesUploader_report_error_template";
                const text = type === 'success'
                    ? index + '. ' + fileName + ' - success'
                    : index + '. ' + fileName + ' - error';

                const template = document.getElementById(templateId).innerHTML.replace(new RegExp("{message}", "gi"), text + message);
                reports.innerHTML = reports.innerHTML + template;
            };

            const upload = () => {
                // upload file
                formData.delete(input.name);
                formData.append(input.name, input.files[currentIndex]);

                fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')['content']
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: formData
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success) {
                            addReport('success', input.files[currentIndex].name, currentIndex + 1, '');
                            currentIndex++;
                            if (currentIndex < input.files.length) {
                                upload();
                            } else {
                                reset();
                                window.location.reload();
                            }
                        } else {
                            let message = '<br/>' + data.message;
                            const keys = Object.keys(data.data.errors);
                            for (let i = 0; i < keys.length; i++) {
                                message = message + '<br/>' + data.data.errors[keys[i]];
                            }
                            addReport('error', input.files[currentIndex].name, currentIndex + 1, message);
                            reset();
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        reset();
                    });
            };
            if (input.files.length > 0) {
                upload();
            }
        };
        input.addEventListener("change", onSelectFiles);
    };

    window.addEventListener('load', () => {
        document.querySelectorAll('[data-upload-multiple-files]').forEach(init);
    });
})();
